import axios from "./axios";

// 获取从今天起范围内的课程数据

export function getKidsCourseData(data) {
  // console.log();
  return axios({
    method: "post",
    url: "kidsCalendarEvent/getKidsPreCourseCalendar",
    data,
  });
}

// 取消订课
export function cancelClass(data) {
  // console.log();
  return axios({
    method: "post",
    url: "reserveClass/cancelReserveClass",
    data,
  });
}

// 分页获取kids完成课程数据
export function getKidsCourseDataByPage(data) {
  return axios({
    method: "post",
    url: "kidsCalendarEvent/getKidsCourseByPage",
    data,
  });
}

// 分页获取kids取消课程数据
export function getKidsCancelledCourseByPage(data) {
  return axios({
    method: "post",
    url: "kidsCalendarEvent/getKidsCourseByPage",
    data,
  });
}

// 课程签到
export function userPunchClock(params) {
  return axios({
    method: "get",
    url: "calendarEvent/saveUserCourseSignIn",
    params,
  });
}

// 用户登入
export function userLogin(params) {
  return axios({
    method: "post",
    url: "login/userLogin",
    data: params,
  });
}

// kids家长登录切换孩子
export function changeCurrentKid(params) {
  return axios({
    method: "post",
    url: "login/SwitchTheUser",
    data: params,
  });
}

// bookClass by date

//通过时间筛选可用老师
export function getTeacherListByDate(params) {
  return axios({
    method: "post",
    url: "reserveClass/getTeacherList",
    data: params,
  });
}

// bookClass by teacher
//获取学生最近上课的老师
export function getTeacherListByStudent(params) {
  return axios({
    method: "post",
    url: "reserveClass/getTeacherList",
    data: params,
  });
}

// 获取老师空闲时间
export function getTeacherScheduling(params) {
  return axios({
    method: "post",
    url: "reserveClass/getTeacherScheduling",
    data: params,
  });
}

// 订课
export function bookClassByDate(params) {
  return axios({
    method: "post",
    url: "reserveClass/addReserveClass",
    data: params,
  });
}

// 合同
export function getMyOrdersInfo() {
  return axios({
    method: "get",
    url: "contract/getUserContract",
  });
}

// 获取国家信息
export function getCountryList() {
  return axios({
    method: "get",
    url: "setting/getSysCountry",
  });
}

// 获取国家信息
export function getTimZoneList() {
  return axios({
    method: "get",
    url: "setting/getTimeZoneList",
  });
}

// 修改个人信息
export function updateKidsUserInfo(params) {
  return axios({
    method: "post",
    url: "KidsUser/updateKidsUserInfo",
    data: params,
  });
}

// 获取国家信息
export function refreshUserSetting() {
  return axios({
    method: "get",
    url: "setting/refreshUserSetting",
  });
}

// 提交学生评价
export function submitStudentComment(params) {
  return axios({
    method: "post",
    url: "kidsCalendarEvent/addTeacherEvaluation",
    data: params,
  });
}

// 获取学生评价
export function getStudentComment(params) {
  return axios({
    method: "post",
    url: "kidsCalendarEvent/getTeacherEvaluation",
    data: params,
  });
}

// 获取老师对学生评价(chapter)
export function getTeacherFeedbackOfChapter(params) {
  return axios({
    method: "post",
    url: "kidsCalendarEvent/getEvaluationByLesson",
    data: params,
  });
}

// 获取老师对学生评价(lesson)
export function getTeacherFeedbackOfLesson(params) {
  return axios({
    method: "post",
    url: "kidsCalendarEvent/getStudentClassEvaluation",
    data: params,
  });
}

// 获取学生课程
export function getUserLessonInfo() {
  return axios({
    method: "get",
    url: "KidsUser/getUserLessonInfo",
  });
}

// 获取学生合同课程进度详情
export function getUserContractInfo() {
  return axios({
    method: "get",
    url: "kidsCalendarEvent/getContractTokenInfo",
  });
}

// 音频转码
export function audioToMp3(params) {
  return axios({
    method: "post",
    url: "kidsCalendarEvent/uploadStudentClassAudio",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: params,
  });
}

// 获取学生homework
export function getUserLessonHomework(params) {
  return axios({
    method: "get",
    url: "kidsCalendarEvent/getKidsHomework",
    params,
  });
}

// 课后作业附件上传
export function uploadHomeworkFile(params) {
  return axios({
    method: "post",
    url: "dashboard/uploadHomeworkFile",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: params,
  });
}
// 添加课后作业
export function addHomework(params) {
  return axios({
    method: "post",
    url: "dashboard/addHomework",
    data: params,
  });
}

// 获取国外音视频服务商的token

export function getTwilioToken(params) {
  return axios({
    method: "get",
    url: "twilioVideo/getTwilioToken",
    params: params,
  });
}
