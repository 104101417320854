export const course = [

    /** Level 1 */

    {
        path: 'lesson1',
        name: 'Lesson1',
        component: () => import('@/views/Course/Level1/Chapter1/Lesson1'),
        meta: {
            title: 'lesson1',
            // noToken: true
        }
    },
    {
        path: 'lesson2',
        name: 'Lesson2',
        component: () => import('@/views/Course/Level1/Chapter1/Lesson2'),
        meta: {
            title: 'lesson2',
            // noToken: true
        }
    },
    {
        path: 'lesson3',
        name: 'Lesson3',
        component: () => import('@/views/Course/Level1/Chapter1/Lesson3'),
        meta: {
            title: 'lesson3',
            // noToken: true
        }
    },
    {
        path: 'lesson4',
        name: 'Lesson4',
        component: () => import('@/views/Course/Level1/Chapter1/Lesson4'),
        meta: {
            title: 'lesson4',
            // noToken: true
        }
    },
    {
        path: 'lesson5',
        name: 'Lesson5',
        component: () => import('@/views/Course/Level1/Chapter1/Lesson5'),
        meta: {
            title: 'lesson5',
            // noToken: true
        }
    },
    {
        path: 'lesson6',
        name: 'Lesson6',
        component: () => import('@/views/Course/Level1/Chapter1/Lesson6'),
        meta: {
            title: 'lesson6',
            // noToken: true
        }
    },
    {
        path: 'lesson7',
        name: 'Lesson7',
        component: () => import('@/views/Course/Level1/Chapter1/Lesson7'),
        meta: {
            title: 'lesson7',
            // noToken: true
        }
    },
    {
        path: 'lesson8',
        name: 'Lesson8',
        component: () => import('@/views/Course/Level1/Chapter1/Lesson8'),
        meta: {
            title: 'lesson8',
            // noToken: true
        }
    },

    {
        path: 'lesson9',
        name: 'Lesson9',
        component: () => import('@/views/Course/Level1/Chapter2/Lesson9'),
        meta: {
            title: 'Lesson9',
            // noToken: true
        }
    }, {
        path: 'lesson10',
        name: 'Lesson10',
        component: () => import('@/views/Course/Level1/Chapter2/Lesson10'),
        meta: {
            title: 'lesson10',
            // noToken: true
        }
    }, {
        path: 'lesson11',
        name: 'Lesson11',
        component: () => import('@/views/Course/Level1/Chapter2/Lesson11'),
        meta: {
            title: 'lesson11',
            // noToken: true
        }
    },
    {
        path: 'lesson12',
        name: 'Lesson12',
        component: () => import('@/views/Course/Level1/Chapter2/Lesson12'),
        meta: {
            title: 'lesson12',
            // noToken: true
        }
    },
    {
        path: 'lesson13',
        name: 'Lesson13',
        component: () => import('@/views/Course/Level1/Chapter2/Lesson13'),
        meta: {
            title: 'lesson13',
            // noToken: true
        }
    },
    {
        path: 'lesson14',
        name: 'Lesson14',
        component: () => import('@/views/Course/Level1/Chapter2/Lesson14'),
        meta: {
            title: 'lesson14',
            // noToken: true
        }
    },
    {
        path: 'lesson15',
        name: 'Lesson15',
        component: () => import('@/views/Course/Level1/Chapter2/Lesson15'),
        meta: {
            title: 'lesson15',
            // noToken: true
        }
    },
    {
        path: 'lesson16',
        name: 'Lesson16',
        component: () => import('@/views/Course/Level1/Chapter2/Lesson16'),
        meta: {
            title: 'lesson16',
            // noToken: true
        }
    },
    {
        path: 'lesson17',
        name: 'Lesson17',
        component: () => import('@/views/Course/Level1/Chapter3/Lesson17'),
        meta: {
            title: 'lesson17',
            // noToken: true
        }
    },
    {
        path: 'lesson18',
        name: 'Lesson18',
        component: () => import('@/views/Course/Level1/Chapter3/Lesson18'),
        meta: {
            title: 'lesson18',
            // noToken: true
        }
    },
    {
        path: 'lesson19',
        name: 'Lesson19',
        component: () => import('@/views/Course/Level1/Chapter3/Lesson19'),
        meta: {
            title: 'lesson19',
            // noToken: true
        }
    },
    {
        path: 'lesson20',
        name: 'Lesson20',
        component: () => import('@/views/Course/Level1/Chapter3/Lesson20'),
        meta: {
            title: 'lesson20',
            // noToken: true
        }
    },
    {
        path: 'lesson21',
        name: 'Lesson21',
        component: () => import('@/views/Course/Level1/Chapter3/Lesson21'),
        meta: {
            title: 'lesson21',
            // noToken: true
        }
    },
    {
        path: 'lesson22',
        name: 'Lesson22',
        component: () => import('@/views/Course/Level1/Chapter3/Lesson22'),
        meta: {
            title: 'lesson22',
            // noToken: true
        }
    },
    {
        path: 'lesson23',
        name: 'Lesson23',
        component: () => import('@/views/Course/Level1/Chapter3/Lesson23'),
        meta: {
            title: 'lesson23',
            // noToken: true
        }
    },
    {
        path: 'lesson24',
        name: 'Lesson24',
        component: () => import('@/views/Course/Level1/Chapter3/Lesson24'),
        meta: {
            title: 'lesson24',
            // noToken: true
        }
    },
    {
        path: 'Lesson25',
        name: 'Lesson25',
        component: () => import('@/views/Course/Level1/Chapter4/Lesson25'),
        meta: {
            title: 'Lesson25',
        }
    },
    {
        path: 'Lesson26',
        name: 'Lesson26',
        component: () => import('@/views/Course/Level1/Chapter4/Lesson26'),
        meta: {
            title: 'Lesson26',
        }
    },
    {
        path: 'Lesson27',
        name: 'Lesson27',
        component: () => import('@/views/Course/Level1/Chapter4/Lesson27'),
        meta: {
            title: 'Lesson27',
        }
    },
    {
        path: 'Lesson28',
        name: 'Lesson28',
        component: () => import('@/views/Course/Level1/Chapter4/Lesson28'),
        meta: {
            title: 'Lesson28',
        }
    },
    {
        path: 'Lesson29',
        name: 'Lesson29',
        component: () => import('@/views/Course/Level1/Chapter4/Lesson29'),
        meta: {
            title: 'Lesson29',
        }
    },
    {
        path: 'Lesson30',
        name: 'Lesson30',
        component: () => import('@/views/Course/Level1/Chapter4/Lesson30'),
        meta: {
            title: 'Lesson30',
        }
    },
    {
        path: 'Lesson31',
        name: 'Lesson31',
        component: () => import('@/views/Course/Level1/Chapter4/Lesson31'),
        meta: {
            title: 'Lesson31',
        }
    },
    {
        path: 'Lesson32',
        name: 'Lesson32',
        component: () => import('@/views/Course/Level1/Chapter4/Lesson32'),
        meta: {
            title: 'Lesson32',
        }
    },
    {
        path: 'Lesson33',
        name: 'Lesson33',
        component: () => import('@/views/Course/Level1/Chapter5/Lesson33'),
        meta: {
            title: 'Lesson33',
        }
    },
    {
        path: 'Lesson34',
        name: 'Lesson34',
        component: () => import('@/views/Course/Level1/Chapter5/Lesson34'),
        meta: {
            title: 'Lesson34',
        }
    },
    {
        path: 'Lesson35',
        name: 'Lesson35',
        component: () => import('@/views/Course/Level1/Chapter5/Lesson35'),
        meta: {
            title: 'Lesson35',
        }
    },
    {
        path: 'Lesson36',
        name: 'Lesson36',
        component: () => import('@/views/Course/Level1/Chapter5/Lesson36'),
        meta: {
            title: 'Lesson36',
        }
    },
    {
        path: 'Lesson37',
        name: 'Lesson37',
        component: () => import('@/views/Course/Level1/Chapter5/Lesson37'),
        meta: {
            title: 'Lesson37',
        }
    },
    {
        path: 'Lesson38',
        name: 'Lesson38',
        component: () => import('@/views/Course/Level1/Chapter5/Lesson38'),
        meta: {
            title: 'Lesson38',
        }
    },
    {
        path: 'Lesson39',
        name: 'Lesson39',
        component: () => import('@/views/Course/Level1/Chapter5/Lesson39'),
        meta: {
            title: 'Lesson39',
        }
    },
    {
        path: 'Lesson40',
        name: 'Lesson40',
        component: () => import('@/views/Course/Level1/Chapter5/Lesson40'),
        meta: {
            title: 'Lesson40',
        }
    },
    {
        path: 'Lesson41',
        name: 'Lesson41',
        component: () => import('@/views/Course/Level1/Chapter6/Lesson41'),
        meta: {
            title: 'Lesson41',
        }
    },
    {
        path: 'Lesson42',
        name: 'Lesson42',
        component: () => import('@/views/Course/Level1/Chapter6/Lesson42'),
        meta: {
            title: 'Lesson42',
        }
    },
    {
        path: 'Lesson43',
        name: 'Lesson43',
        component: () => import('@/views/Course/Level1/Chapter6/Lesson43'),
        meta: {
            title: 'Lesson43',
        }
    },
    {
        path: 'Lesson44',
        name: 'Lesson44',
        component: () => import('@/views/Course/Level1/Chapter6/Lesson44'),
        meta: {
            title: 'Lesson44',
        }
    },
    {
        path: 'Lesson45',
        name: 'Lesson45',
        component: () => import('@/views/Course/Level1/Chapter6/Lesson45'),
        meta: {
            title: 'Lesson45',
        }
    },
    {
        path: 'Lesson46',
        name: 'Lesson46',
        component: () => import('@/views/Course/Level1/Chapter6/Lesson46'),
        meta: {
            title: 'Lesson46',
        }
    },
    {
        path: 'Lesson47',
        name: 'Lesson47',
        component: () => import('@/views/Course/Level1/Chapter6/Lesson47'),
        meta: {
            title: 'Lesson47',
        }
    },
    {
        path: 'Lesson48',
        name: 'Lesson48',
        component: () => import('@/views/Course/Level1/Chapter6/Lesson48'),
        meta: {
            title: 'Lesson48',
        }
    },
    {
        path: 'Lesson49',
        name: 'Lesson49',
        component: () => import('@/views/Course/Level1/Chapter7/Lesson49'),
        meta: {
            title: 'Lesson49',
        }
    },
    {
        path: 'Lesson50',
        name: 'Lesson50',
        component: () => import('@/views/Course/Level1/Chapter7/Lesson50'),
        meta: {
            title: 'Lesson50',
        }
    },
    {
        path: 'Lesson51',
        name: 'Lesson51',
        component: () => import('@/views/Course/Level1/Chapter7/Lesson51'),
        meta: {
            title: 'Lesson51',
        }
    },
    {
        path: 'Lesson52',
        name: 'Lesson52',
        component: () => import('@/views/Course/Level1/Chapter7/Lesson52'),
        meta: {
            title: 'Lesson52',
        }
    },
    {
        path: 'Lesson53',
        name: 'Lesson53',
        component: () => import('@/views/Course/Level1/Chapter7/Lesson53'),
        meta: {
            title: 'Lesson53',
        }
    },
    {
        path: 'Lesson54',
        name: 'Lesson54',
        component: () => import('@/views/Course/Level1/Chapter7/Lesson54'),
        meta: {
            title: 'Lesson54',
        }
    },
    {
        path: 'Lesson55',
        name: 'Lesson55',
        component: () => import('@/views/Course/Level1/Chapter7/Lesson55'),
        meta: {
            title: 'Lesson55',
        }
    },
    {
        path: 'Lesson56',
        name: 'Lesson56',
        component: () => import('@/views/Course/Level1/Chapter7/Lesson56'),
        meta: {
            title: 'Lesson56',
        }
    },
    {
        path: 'Lesson57',
        name: 'Lesson57',
        component: () => import('@/views/Course/Level1/Chapter8/Lesson57'),
        meta: {
            title: 'Lesson57',
        }
    },
    {
        path: 'Lesson58',
        name: 'Lesson58',
        component: () => import('@/views/Course/Level1/Chapter8/Lesson58'),
        meta: {
            title: 'Lesson58',
        }
    },
    {
        path: 'Lesson59',
        name: 'Lesson59',
        component: () => import('@/views/Course/Level1/Chapter8/Lesson59'),
        meta: {
            title: 'Lesson59',
        }
    },
    {
        path: 'Lesson60',
        name: 'Lesson60',
        component: () => import('@/views/Course/Level1/Chapter8/Lesson60'),
        meta: {
            title: 'Lesson60',
        }
    },
    {
        path: 'Lesson61',
        name: 'Lesson61',
        component: () => import('@/views/Course/Level1/Chapter8/Lesson61'),
        meta: {
            title: 'Lesson61',
        }
    },
    {
        path: 'Lesson62',
        name: 'Lesson62',
        component: () => import('@/views/Course/Level1/Chapter8/Lesson62'),
        meta: {
            title: 'Lesson62',
        }
    },
    {
        path: 'Lesson63',
        name: 'Lesson63',
        component: () => import('@/views/Course/Level1/Chapter8/Lesson63'),
        meta: {
            title: 'Lesson63',
        }
    },
    {
        path: 'Lesson64',
        name: 'Lesson64',
        component: () => import('@/views/Course/Level1/Chapter8/Lesson64'),
        meta: {
            title: 'Lesson64',
        }
    },

    /** Level 2 */

    {
        path: 'lesson65',
        name: 'Lesson65',
        component: () => import('@/views/Course/Level2/Chapter1/Lesson1'),
        meta: {
            title: 'lesson65',
            // noToken: true
        }
    },
    {
        path: 'lesson66',
        name: 'Lesson66',
        component: () => import('@/views/Course/Level2/Chapter1/Lesson2'),
        meta: {
            title: 'lesson66',
            // noToken: true
        }
    },
    {
        path: 'lesson67',
        name: 'Lesson67',
        component: () => import('@/views/Course/Level2/Chapter1/Lesson3'),
        meta: {
            title: 'lesson67',
            // noToken: true
        }
    },
    {
        path: 'lesson68',
        name: 'Lesson68',
        component: () => import('@/views/Course/Level2/Chapter1/Lesson4'),
        meta: {
            title: 'lesson68',
            // noToken: true
        }
    },
    {
        path: 'lesson69',
        name: 'Lesson69',
        component: () => import('@/views/Course/Level2/Chapter1/Lesson5'),
        meta: {
            title: 'lesson69',
            // noToken: true
        }
    },
    {
        path: 'lesson70',
        name: 'Lesson70',
        component: () => import('@/views/Course/Level2/Chapter1/Lesson6'),
        meta: {
            title: 'lesson70',
            // noToken: true
        }
    },
    {
        path: 'lesson71',
        name: 'Lesson71',
        component: () => import('@/views/Course/Level2/Chapter1/Lesson7'),
        meta: {
            title: 'lesson71',
            // noToken: true
        }
    },
    {
        path: 'lesson72',
        name: 'Lesson72',
        component: () => import('@/views/Course/Level2/Chapter1/Lesson8'),
        meta: {
            title: 'lesson72',
            // noToken: true
        }
    },

    {
        path: 'lesson73',
        name: 'Lesson73',
        component: () => import('@/views/Course/Level2/Chapter2/Lesson9'),
        meta: {
            title: 'Lesson73',
            // noToken: true
        }
    },
    {
        path: 'lesson74',
        name: 'Lesson74',
        component: () => import('@/views/Course/Level2/Chapter2/Lesson10'),
        meta: {
            title: 'lesson74',
            // noToken: true
        }
    }, 
    {
        path: 'lesson75',
        name: 'Lesson75',
        component: () => import('@/views/Course/Level2/Chapter2/Lesson11'),
        meta: {
            title: 'lesson75',
            // noToken: true
        }
    },
    {
        path: 'lesson76',
        name: 'Lesson76',
        component: () => import('@/views/Course/Level2/Chapter2/Lesson12'),
        meta: {
            title: 'lesson76',
            // noToken: true
        }
    },
    {
        path: 'lesson77',
        name: 'Lesson77',
        component: () => import('@/views/Course/Level2/Chapter2/Lesson13'),
        meta: {
            title: 'lesson77',
            // noToken: true
        }
    },
    {
        path: 'lesson78',
        name: 'Lesson78',
        component: () => import('@/views/Course/Level2/Chapter2/Lesson14'),
        meta: {
            title: 'lesson78',
            // noToken: true
        }
    },
    {
        path: 'lesson79',
        name: 'Lesson79',
        component: () => import('@/views/Course/Level2/Chapter2/Lesson15'),
        meta: {
            title: 'lesson79',
            // noToken: true
        }
    },
    {
        path: 'lesson80',
        name: 'Lesson80',
        component: () => import('@/views/Course/Level2/Chapter2/Lesson16'),
        meta: {
            title: 'lesson80',
            // noToken: true
        }
    },
    {
        path: 'lesson81',
        name: 'Lesson81',
        component: () => import('@/views/Course/Level2/Chapter3/Lesson17'),
        meta: {
            title: 'lesson81',
            // noToken: true
        }
    },
    // {
    //     path: 'lesson82',
    //     name: 'Lesson82',
    //     component: () => import('@/views/Course/Level2/Chapter3/Lesson18'),
    //     meta: {
    //         title: 'lesson82',
    //         // noToken: true
    //     }
    // },
    {
        path: 'lesson83',
        name: 'Lesson83',
        component: () => import('@/views/Course/Level2/Chapter3/Lesson19'),
        meta: {
            title: 'lesson83',
            // noToken: true
        }
    },
    // {
    //     path: 'lesson84',
    //     name: 'Lesson84',
    //     component: () => import('@/views/Course/Level2/Chapter3/Lesson20'),
    //     meta: {
    //         title: 'lesson84',
    //         // noToken: true
    //     }
    // },
    // {
    //     path: 'lesson85',
    //     name: 'Lesson85',
    //     component: () => import('@/views/Course/Level2/Chapter3/Lesson21'),
    //     meta: {
    //         title: 'lesson85',
    //         // noToken: true
    //     }
    // },
    // {
    //     path: 'lesson86',
    //     name: 'Lesson86',
    //     component: () => import('@/views/Course/Level2/Chapter3/Lesson22'),
    //     meta: {
    //         title: 'lesson86',
    //         // noToken: true
    //     }
    // },
    // {
    //     path: 'lesson87',
    //     name: 'Lesson87',
    //     component: () => import('@/views/Course/Level2/Chapter3/Lesson23'),
    //     meta: {
    //         title: 'lesson87',
    //         // noToken: true
    //     }
    // },
    // {
    //     path: 'lesson88',
    //     name: 'Lesson88',
    //     component: () => import('@/views/Course/Level2/Chapter3/Lesson24'),
    //     meta: {
    //         title: 'lesson88',
    //         // noToken: true
    //     }
    // },
    // {
    //     path: 'Lesson89',
    //     name: 'Lesson89',
    //     component: () => import('@/views/Course/Level2/Chapter4/Lesson25'),
    //     meta: {
    //         title: 'Lesson89',
    //     }
    // },
    // {
    //     path: 'Lesson90',
    //     name: 'Lesson90',
    //     component: () => import('@/views/Course/Level2/Chapter4/Lesson26'),
    //     meta: {
    //         title: 'Lesson90',
    //     }
    // },
    // {
    //     path: 'Lesson91',
    //     name: 'Lesson91',
    //     component: () => import('@/views/Course/Level2/Chapter4/Lesson27'),
    //     meta: {
    //         title: 'Lesson91',
    //     }
    // },
    // {
    //     path: 'Lesson92',
    //     name: 'Lesson92',
    //     component: () => import('@/views/Course/Level2/Chapter4/Lesson28'),
    //     meta: {
    //         title: 'Lesson92',
    //     }
    // },
    // {
    //     path: 'Lesson93',
    //     name: 'Lesson93',
    //     component: () => import('@/views/Course/Level2/Chapter4/Lesson29'),
    //     meta: {
    //         title: 'Lesson93',
    //     }
    // },
    // {
    //     path: 'Lesson94',
    //     name: 'Lesson94',
    //     component: () => import('@/views/Course/Level2/Chapter4/Lesson30'),
    //     meta: {
    //         title: 'Lesson94',
    //     }
    // },
    // {
    //     path: 'Lesson95',
    //     name: 'Lesson95',
    //     component: () => import('@/views/Course/Level2/Chapter4/Lesson31'),
    //     meta: {
    //         title: 'Lesson95',
    //     }
    // },
    // {
    //     path: 'Lesson96',
    //     name: 'Lesson96',
    //     component: () => import('@/views/Course/Level2/Chapter4/Lesson32'),
    //     meta: {
    //         title: 'Lesson96',
    //     }
    // },
    // {
    //     path: 'Lesson97',
    //     name: 'Lesson97',
    //     component: () => import('@/views/Course/Level2/Chapter5/Lesson33'),
    //     meta: {
    //         title: 'Lesson97',
    //     }
    // },
    // {
    //     path: 'Lesson98',
    //     name: 'Lesson98',
    //     component: () => import('@/views/Course/Level2/Chapter5/Lesson34'),
    //     meta: {
    //         title: 'Lesson98',
    //     }
    // },
    // {
    //     path: 'Lesson99',
    //     name: 'Lesson99',
    //     component: () => import('@/views/Course/Level2/Chapter5/Lesson35'),
    //     meta: {
    //         title: 'Lesson99',
    //     }
    // },
    // {
    //     path: 'Lesson100',
    //     name: 'Lesson100',
    //     component: () => import('@/views/Course/Level2/Chapter5/Lesson36'),
    //     meta: {
    //         title: 'Lesson100',
    //     }
    // },
    // {
    //     path: 'Lesson101',
    //     name: 'Lesson101',
    //     component: () => import('@/views/Course/Level2/Chapter5/Lesson37'),
    //     meta: {
    //         title: 'Lesson101',
    //     }
    // },
    // {
    //     path: 'Lesson102',
    //     name: 'Lesson102',
    //     component: () => import('@/views/Course/Level2/Chapter5/Lesson38'),
    //     meta: {
    //         title: 'Lesson102',
    //     }
    // },
    // {
    //     path: 'Lesson103',
    //     name: 'Lesson103',
    //     component: () => import('@/views/Course/Level2/Chapter5/Lesson39'),
    //     meta: {
    //         title: 'Lesson103',
    //     }
    // },
    // {
    //     path: 'Lesson104',
    //     name: 'Lesson104',
    //     component: () => import('@/views/Course/Level2/Chapter5/Lesson40'),
    //     meta: {
    //         title: 'Lesson104',
    //     }
    // },
    // {
    //     path: 'Lesson105',
    //     name: 'Lesson105',
    //     component: () => import('@/views/Course/Level2/Chapter6/Lesson41'),
    //     meta: {
    //         title: 'Lesson105',
    //     }
    // },
    // {
    //     path: 'Lesson106',
    //     name: 'Lesson106',
    //     component: () => import('@/views/Course/Level2/Chapter6/Lesson42'),
    //     meta: {
    //         title: 'Lesson106',
    //     }
    // },
    // {
    //     path: 'Lesson107',
    //     name: 'Lesson107',
    //     component: () => import('@/views/Course/Level2/Chapter6/Lesson43'),
    //     meta: {
    //         title: 'Lesson107',
    //     }
    // },
    // {
    //     path: 'Lesson108',
    //     name: 'Lesson108',
    //     component: () => import('@/views/Course/Level2/Chapter6/Lesson44'),
    //     meta: {
    //         title: 'Lesson108',
    //     }
    // },
    // {
    //     path: 'Lesson109',
    //     name: 'Lesson109',
    //     component: () => import('@/views/Course/Level2/Chapter6/Lesson45'),
    //     meta: {
    //         title: 'Lesson109',
    //     }
    // },
    // {
    //     path: 'Lesson110',
    //     name: 'Lesson110',
    //     component: () => import('@/views/Course/Level2/Chapter6/Lesson46'),
    //     meta: {
    //         title: 'Lesson110',
    //     }
    // },
    // {
    //     path: 'Lesson111',
    //     name: 'Lesson111',
    //     component: () => import('@/views/Course/Level2/Chapter6/Lesson47'),
    //     meta: {
    //         title: 'Lesson111',
    //     }
    // },
    // {
    //     path: 'Lesson112',
    //     name: 'Lesson112',
    //     component: () => import('@/views/Course/Level2/Chapter6/Lesson48'),
    //     meta: {
    //         title: 'Lesson112',
    //     }
    // },
    // {
    //     path: 'Lesson113',
    //     name: 'Lesson113',
    //     component: () => import('@/views/Course/Level2/Chapter7/Lesson49'),
    //     meta: {
    //         title: 'Lesson113',
    //     }
    // },
    // {
    //     path: 'Lesson114',
    //     name: 'Lesson114',
    //     component: () => import('@/views/Course/Level2/Chapter7/Lesson50'),
    //     meta: {
    //         title: 'Lesson114',
    //     }
    // },
    // {
    //     path: 'Lesson115',
    //     name: 'Lesson115',
    //     component: () => import('@/views/Course/Level2/Chapter7/Lesson51'),
    //     meta: {
    //         title: 'Lesson115',
    //     }
    // },
    // {
    //     path: 'Lesson116',
    //     name: 'Lesson116',
    //     component: () => import('@/views/Course/Level2/Chapter7/Lesson52'),
    //     meta: {
    //         title: 'Lesson116',
    //     }
    // },
    // {
    //     path: 'Lesson117',
    //     name: 'Lesson117',
    //     component: () => import('@/views/Course/Level2/Chapter7/Lesson53'),
    //     meta: {
    //         title: 'Lesson117',
    //     }
    // },
    // {
    //     path: 'Lesson118',
    //     name: 'Lesson118',
    //     component: () => import('@/views/Course/Level2/Chapter7/Lesson54'),
    //     meta: {
    //         title: 'Lesson118',
    //     }
    // },
    // {
    //     path: 'Lesson119',
    //     name: 'Lesson119',
    //     component: () => import('@/views/Course/Level2/Chapter7/Lesson55'),
    //     meta: {
    //         title: 'Lesson119',
    //     }
    // },
    // {
    //     path: 'Lesson120',
    //     name: 'Lesson120',
    //     component: () => import('@/views/Course/Level2/Chapter7/Lesson56'),
    //     meta: {
    //         title: 'Lesson120',
    //     }
    // },
    // {
    //     path: 'Lesson121',
    //     name: 'Lesson121',
    //     component: () => import('@/views/Course/Level2/Chapter8/Lesson57'),
    //     meta: {
    //         title: 'Lesson121',
    //     }
    // },
    // {
    //     path: 'Lesson122',
    //     name: 'Lesson122',
    //     component: () => import('@/views/Course/Level2/Chapter8/Lesson58'),
    //     meta: {
    //         title: 'Lesson122',
    //     }
    // },
    // {
    //     path: 'Lesson123',
    //     name: 'Lesson123',
    //     component: () => import('@/views/Course/Level2/Chapter8/Lesson59'),
    //     meta: {
    //         title: 'Lesson123',
    //     }
    // },
    // {
    //     path: 'Lesson124',
    //     name: 'Lesson124',
    //     component: () => import('@/views/Course/Level2/Chapter8/Lesson60'),
    //     meta: {
    //         title: 'Lesson124',
    //     }
    // },
    // {
    //     path: 'Lesson125',
    //     name: 'Lesson125',
    //     component: () => import('@/views/Course/Level2/Chapter8/Lesson61'),
    //     meta: {
    //         title: 'Lesson125',
    //     }
    // },
    // {
    //     path: 'Lesson126',
    //     name: 'Lesson126',
    //     component: () => import('@/views/Course/Level2/Chapter8/Lesson62'),
    //     meta: {
    //         title: 'Lesson126',
    //     }
    // },
    // {
    //     path: 'Lesson127',
    //     name: 'Lesson127',
    //     component: () => import('@/views/Course/Level2/Chapter8/Lesson63'),
    //     meta: {
    //         title: 'Lesson127',
    //     }
    // },
    // {
    //     path: 'Lesson128',
    //     name: 'Lesson128',
    //     component: () => import('@/views/Course/Level2/Chapter8/Lesson64'),
    //     meta: {
    //         title: 'Lesson128',
    //     }
    // },



    // {
    //     path: 'lesson999',
    //     name: 'Lesson999',
    //     component: () => import('@/views/Course/Game'),
    //     meta: {
    //         title: 'lesson999',
    //         // noToken: true
    //     }
    // },
]