export const preview = [{
    path: 'previewLesson1',
    name: 'PreviewLesson1',
    component: () => import('@/views/Preview/Level1/Chapter1/Lesson1'),
    meta: {
        title: 'PreviewLesson1',
    }
}, 
{
    path: 'previewLesson2',
    name: 'PreviewLesson2',
    component: () => import('@/views/Preview/Level1/Chapter1/Lesson2'),
    meta: {
        title: 'PreviewLesson2',
    }
},

{
    path: 'previewLesson3',
    name: 'PreviewLesson3',
    component: () => import('@/views/Preview/Level1/Chapter1/Lesson3'),
    meta: {
        title: 'PreviewLesson3',
    }
},
{
    path: 'previewLesson4',
    name: 'PreviewLesson4',
    component: () => import('@/views/Preview/Level1/Chapter1/Lesson4'),
    meta: {
        title: 'PreviewLesson4',
    }
},
{
    path: 'previewLesson5',
    name: 'PreviewLesson5',
    component: () => import('@/views/Preview/Level1/Chapter1/Lesson5'),
    meta: {
        title: 'PreviewLesson5',
    }
},
{
    path: 'previewLesson6',
    name: 'PreviewLesson6',
    component: () => import('@/views/Preview/Level1/Chapter1/Lesson6'),
    meta: {
        title: 'PreviewLesson6',
    }
},
{
    path: 'previewLesson7',
    name: 'PreviewLesson7',
    component: () => import('@/views/Preview/Level1/Chapter1/Lesson7'),
    meta: {
        title: 'PreviewLesson7',
    }
},
{
    path: 'previewLesson9',
    name: 'PreviewLesson9',
    component: () => import('@/views/Preview/Level1/Chapter2/Lesson9'),
    meta: {
        title: 'PreviewLesson9',
    }
},
{
    path: 'previewLesson10',
    name: 'PreviewLesson10',
    component: () => import('@/views/Preview/Level1/Chapter2/Lesson10'),
    meta: {
        title: 'PreviewLesson10',
    }
},
{
    path: 'previewLesson11',
    name: 'PreviewLesson11',
    component: () => import('@/views/Preview/Level1/Chapter2/Lesson11'),
    meta: {
        title: 'PreviewLesson11',
    }
},
{
    path: 'previewLesson12',
    name: 'PreviewLesson12',
    component: () => import('@/views/Preview/Level1/Chapter2/Lesson12'),
    meta: {
        title: 'PreviewLesson12',
    }
},
{
    path: 'previewLesson13',
    name: 'PreviewLesson13',
    component: () => import('@/views/Preview/Level1/Chapter2/Lesson13'),
    meta: {
        title: 'PreviewLesson13',
    }
},
{
    path: 'previewLesson14',
    name: 'PreviewLesson14',
    component: () => import('@/views/Preview/Level1/Chapter2/Lesson14'),
    meta: {
        title: 'PreviewLesson14',
    }
},
{
    path: 'previewLesson15',
    name: 'PreviewLesson15',
    component: () => import('@/views/Preview/Level1/Chapter2/Lesson15'),
    meta: {
        title: 'PreviewLesson15',
    }
},
{
    path: 'previewLesson17',
    name: 'PreviewLesson17',
    component: () => import('@/views/Preview/Level1/Chapter3/Lesson17'),
    meta: {
        title: 'PreviewLesson17',
    }
},

{
    path: 'previewLesson18',
    name: 'PreviewLesson18',
    component: () => import('@/views/Preview/Level1/Chapter3/Lesson18'),
    meta: {
        title: 'PreviewLesson18',
    }
},
{
    path: 'previewLesson19',
    name: 'PreviewLesson19',
    component: () => import('@/views/Preview/Level1/Chapter3/Lesson19'),
    meta: {
        title: 'PreviewLesson19',
    }
},
{
    path: 'previewLesson20',
    name: 'PreviewLesson20',
    component: () => import('@/views/Preview/Level1/Chapter3/Lesson20'),
    meta: {
        title: 'PreviewLesson20',
    }
},
{
    path: 'previewLesson21',
    name: 'PreviewLesson21',
    component: () => import('@/views/Preview/Level1/Chapter3/Lesson21'),
    meta: {
        title: 'PreviewLesson21',
    }
},
{
    path: 'previewLesson22',
    name: 'PreviewLesson22',
    component: () => import('@/views/Preview/Level1/Chapter3/Lesson22'),
    meta: {
        title: 'PreviewLesson22',
    }
},
{
    path: 'previewLesson23',
    name: 'PreviewLesson23',
    component: () => import('@/views/Preview/Level1/Chapter3/Lesson23'),
    meta: {
        title: 'PreviewLesson23',
    }
},
{
    path: 'previewLesson25',
    name: 'PreviewLesson25',
    component: () => import('@/views/Preview/Level1/Chapter4/Lesson25'),
    meta: {
        title: 'PreviewLesson25',
    }
},
{
    path: 'previewLesson26',
    name: 'PreviewLesson26',
    component: () => import('@/views/Preview/Level1/Chapter4/Lesson26'),
    meta: {
        title: 'PreviewLesson26',
    }
},
{
    path: 'previewLesson27',
    name: 'PreviewLesson27',
    component: () => import('@/views/Preview/Level1/Chapter4/Lesson27'),
    meta: {
        title: 'PreviewLesson27',
    }
},
{
    path: 'previewLesson28',
    name: 'PreviewLesson28',
    component: () => import('@/views/Preview/Level1/Chapter4/Lesson28'),
    meta: {
        title: 'PreviewLesson28',
    }
},
{
    path: 'previewLesson29',
    name: 'PreviewLesson29',
    component: () => import('@/views/Preview/Level1/Chapter4/Lesson29'),
    meta: {
        title: 'PreviewLesson29',
    }
},
{
    path: 'previewLesson30',
    name: 'PreviewLesson30',
    component: () => import('@/views/Preview/Level1/Chapter4/Lesson30'),
    meta: {
        title: 'PreviewLesson30',
    }
},
{
    path: 'previewLesson31',
    name: 'PreviewLesson31',
    component: () => import('@/views/Preview/Level1/Chapter4/Lesson31'),
    meta: {
        title: 'PreviewLesson31',
    }
},
{
    path: 'previewLesson33',
    name: 'PreviewLesson33',
    component: () => import('@/views/Preview/Level1/Chapter5/Lesson33'),
    meta: {
        title: 'PreviewLesson33',
    }
},
{
    path: 'previewLesson34',
    name: 'PreviewLesson34',
    component: () => import('@/views/Preview/Level1/Chapter5/Lesson34'),
    meta: {
        title: 'PreviewLesson34',
    }
},
{
    path: 'previewLesson35',
    name: 'PreviewLesson35',
    component: () => import('@/views/Preview/Level1/Chapter5/Lesson35'),
    meta: {
        title: 'PreviewLesson35',
    }
},
{
    path: 'previewLesson36',
    name: 'PreviewLesson36',
    component: () => import('@/views/Preview/Level1/Chapter5/Lesson36'),
    meta: {
        title: 'PreviewLesson36',
    }
},
{
    path: 'previewLesson37',
    name: 'PreviewLesson37',
    component: () => import('@/views/Preview/Level1/Chapter5/Lesson37'),
    meta: {
        title: 'PreviewLesson37',
    }
},
{
    path: 'previewLesson38',
    name: 'PreviewLesson38',
    component: () => import('@/views/Preview/Level1/Chapter5/Lesson38'),
    meta: {
        title: 'PreviewLesson38',
    }
},
{
    path: 'previewLesson39',
    name: 'PreviewLesson39',
    component: () => import('@/views/Preview/Level1/Chapter5/Lesson39'),
    meta: {
        title: 'PreviewLesson39',
    }
},
{
    path: 'previewLesson41',
    name: 'PreviewLesson41',
    component: () => import('@/views/Preview/Level1/Chapter6/Lesson41'),
    meta: {
        title: 'PreviewLesson41',
    }
},
{
    path: 'previewLesson42',
    name: 'PreviewLesson42',
    component: () => import('@/views/Preview/Level1/Chapter6/Lesson42'),
    meta: {
        title: 'PreviewLesson42',
    }
},
{
    path: 'previewLesson43',
    name: 'PreviewLesson43',
    component: () => import('@/views/Preview/Level1/Chapter6/Lesson43'),
    meta: {
        title: 'PreviewLesson43',
    }
},
{
    path: 'previewLesson44',
    name: 'PreviewLesson44',
    component: () => import('@/views/Preview/Level1/Chapter6/Lesson44'),
    meta: {
        title: 'PreviewLesson44',
    }
},
{
    path: 'previewLesson45',
    name: 'PreviewLesson45',
    component: () => import('@/views/Preview/Level1/Chapter6/Lesson45'),
    meta: {
        title: 'PreviewLesson45',
    }
},
{
    path: 'previewLesson46',
    name: 'PreviewLesson46',
    component: () => import('@/views/Preview/Level1/Chapter6/Lesson46'),
    meta: {
        title: 'PreviewLesson46',
    }
},
{
    path: 'previewLesson47',
    name: 'PreviewLesson47',
    component: () => import('@/views/Preview/Level1/Chapter6/Lesson47'),
    meta: {
        title: 'PreviewLesson47',
    }
},
{
    path: 'previewLesson49',
    name: 'PreviewLesson49',
    component: () => import('@/views/Preview/Level1/Chapter7/Lesson49'),
    meta: {
        title: 'PreviewLesson49',
    }
},
{
    path: 'previewLesson50',
    name: 'PreviewLesson50',
    component: () => import('@/views/Preview/Level1/Chapter7/Lesson50'),
    meta: {
        title: 'PreviewLesson50',
    }
},
{
    path: 'previewLesson51',
    name: 'PreviewLesson51',
    component: () => import('@/views/Preview/Level1/Chapter7/Lesson51'),
    meta: {
        title: 'PreviewLesson51',
    }
},
{
    path: 'previewLesson52',
    name: 'PreviewLesson52',
    component: () => import('@/views/Preview/Level1/Chapter7/Lesson52'),
    meta: {
        title: 'PreviewLesson52',
    }
},
{
    path: 'previewLesson53',
    name: 'PreviewLesson53',
    component: () => import('@/views/Preview/Level1/Chapter7/Lesson53'),
    meta: {
        title: 'PreviewLesson53',
    }
},
{
    path: 'previewLesson54',
    name: 'PreviewLesson54',
    component: () => import('@/views/Preview/Level1/Chapter7/Lesson54'),
    meta: {
        title: 'PreviewLesson54',
    }
},
{
    path: 'previewLesson55',
    name: 'PreviewLesson55',
    component: () => import('@/views/Preview/Level1/Chapter7/Lesson55'),
    meta: {
        title: 'PreviewLesson55',
    }
},
{
    path: 'previewLesson57',
    name: 'PreviewLesson57',
    component: () => import('@/views/Preview/Level1/Chapter8/Lesson57'),
    meta: {
        title: 'PreviewLesson57',
    }
},
{
    path: 'previewLesson58',
    name: 'PreviewLesson58',
    component: () => import('@/views/Preview/Level1/Chapter8/Lesson58'),
    meta: {
        title: 'PreviewLesson58',
    }
},
{
    path: 'previewLesson59',
    name: 'PreviewLesson59',
    component: () => import('@/views/Preview/Level1/Chapter8/Lesson59'),
    meta: {
        title: 'PreviewLesson59',
    }
},
{
    path: 'previewLesson60',
    name: 'PreviewLesson60',
    component: () => import('@/views/Preview/Level1/Chapter8/Lesson60'),
    meta: {
        title: 'PreviewLesson60',
    }
},
{
    path: 'previewLesson61',
    name: 'PreviewLesson61',
    component: () => import('@/views/Preview/Level1/Chapter8/Lesson61'),
    meta: {
        title: 'PreviewLesson61',
    }
},
{
    path: 'previewLesson62',
    name: 'PreviewLesson62',
    component: () => import('@/views/Preview/Level1/Chapter8/Lesson62'),
    meta: {
        title: 'PreviewLesson62',
    }
},
{
    path: 'previewLesson63',
    name: 'PreviewLesson63',
    component: () => import('@/views/Preview/Level1/Chapter8/Lesson63'),
    meta: {
        title: 'PreviewLesson63',
    }
},

]
