import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  course
} from "./course.js"
import {
  preview
} from "./preview.js"

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: "/dashboard",
    // redirect: "/course",
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    redirect: "/dashboard/home",
    component: () => import( /* webpackChunkName: "dashboard" */ '@/views/Dashboard'),
    children: [{
        path: 'home',
        name: 'Home',
        component: () => import('@/views/Dashboard/Home'),
        meta: {
          keepAlive: true,
        }
      },
      {
        path: 'bookclass',
        name: 'BookClass',
        component: () => import('@/views/Dashboard/BookClass'),
        meta: {
          title: 'bookclass',
          // noToken: true
        }
      },
      {
        path: 'myorders',
        name: 'MyOrders',
        component: () => import('@/views/Dashboard/MyOrders'),
        meta: {
          title: 'myorders',
          // noToken: true
        }
      },
      {
        path: 'prices',
        name: 'Prices',
        component: () => import('@/views/Dashboard/Prices'),
        meta: {
          title: 'prices',
          // noToken: true
        }
      },
      {
        path: 'profile',
        // redirect:'/profile?page=0',
        name: 'Profile',
        component: () => import('@/views/Dashboard/Profile'),
        meta: {
          title: 'profile',
          // noToken: true
        }
      },
      {
        path: 'referafriend',
        name: 'ReferAFriend',
        component: () => import('@/views/Dashboard/ReferAFriend'),
        meta: {
          title: 'referafriend',
          // noToken: true
        }
      },
      {
        path: 'Review',
        name: 'Review',
        component: () => import('@/views/Dashboard/Review'),
        meta: {
          title: 'Review',
          // noToken: true
        }
      },
      {
        path: 'Schedule',
        name: 'Schedule',
        component: () => import('@/views/Dashboard/Schedule'),
        meta: {
          title: 'Schedule',
          // noToken: true
        }
      }
    ]
  },
  {
    path: '/course/calendarEventId=:calendarEventId',
    name: 'Course',
    component: () => import( /* webpackChunkName: "course" */ '@/views/Course'),
    children: course,
  },
  {
    path: '/preview',
    name: 'Preview',
    component: () => import( /* webpackChunkName: "preview" */ '@/views/Preview'),
    children: preview,
  },
  {
    path: '/login',
    name: 'LogIn',
    component: () => import( /* webpackChunkName: "login" */ '@/views/Login'),
  },
  // {
  //   path: '/test/room:roomId',
  //   name: 'Test',
  //   component: () => import( /* webpackChunkName: "test" */ '@/views/Test'),
  //   children: [{
  //       path: 'son1',
  //       name: 'son1',
  //       component: () => import('@/views/Test/son1.vue'),
  //     },
  //     {
  //       path: 'son2',
  //       name: 'son2',
  //       component: () => import('@/views/Test/son2.vue'),
  //     },

  //   ]
  // },




]

const router = new VueRouter({
  routes
})

export default router