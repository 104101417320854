import Axios from "axios";
import vue from "@/main";

/*是否有请求正在刷新token*/
window.isRefreshing = false;
/*被挂起的请求数组*/
let refreshSubscribers = [];
/*获取刷新token请求的token*/
function getRefreshToken() {
  return JSON.parse(localStorage.userData).refreshToken;
}
/*push所有请求到数组中*/
function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}
/*刷新请求（refreshSubscribers数组中的请求得到新的token之后会自执行，用新的token去请求数据）*/
function onRrefreshed(token) {
  refreshSubscribers.map((cb) => cb(token));
}
/*判断token是否过期*/
function isTokenExpired() {
  /*从sessionStorage中取出token过期时间*/
  let expiredTime =
    new Date(JSON.parse(localStorage.userData).expiredTime).getTime() / 1000;
  /*获取本地时间*/
  let nowTime = new Date().getTime() / 1000;
  /*获取校验时间差*/
  // let diffTime = JSON.parse(sessionStorage.diffTime)
  /*校验本地时间*/
  // nowTime -= diffTime
  /*如果 < 5分钟，则说明即将过期*/
  return expiredTime - nowTime < 60 * 5;
  // return false
}

// console.log(process.env);
let axios = Axios.create({
  // baseURL: process.env.API_ROOT,
  baseURL: `${process.env.VUE_APP_URL}/SmartLingo/`, //before
  // baseURL: 'https://api.nihaocafe.com/SmartLingo/',
  // baseURL: 'http://47.116.22.56:8084/SmartLingo/',
  // baseURL: 'http://49.235.8.136:8084/SmartLingo/',
  // baseURL: 'http://192.168.1.217:8084/SmartLingo/', //hu
  // baseURL: 'https://testapi.nihaocafe.com/SmartLingo/', // shmily
  // https://testapi.nihaocafe.com

  timeout: 1000 * 30,
  // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  headers: {
    "Content-Type": "application/json",
  },
});
//
let loading;
// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    `
  // console.log(config)`;
    loading = vue.$loading({
      lock: true,
      text: "",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.3)",
    });
    // console.log(loading);

    // let userData = {};
    // if(localStorage.userData){
    //   userData = JSON.parse(localStorage.userData)
    // }
    // config.headers.token = userData.token;

    const authTmp = localStorage.userData;
    /*判断是否已登录*/
    if (authTmp) {
      /*解析登录信息*/
      let auth = JSON.parse(authTmp);
      // config.data ? config.data.userId = auth.userSetting.userId : '';
      /*判断auth是否存在*/
      if (auth) {
        /*在请求头中添加token类型、token*/
        // config.headers.Authorization = auth.token_type + ' ' + auth.token
        config.headers.token = auth.token;
        config.headers.refreshToken = auth.refreshToken;
        config.headers.userId = auth.userSetting.userId;
        config.headers["Accept-Language"] = "en";
        if (auth.userSetting.showLanguage) {
          config.headers["Accept-Language"] = auth.userSetting.showLanguage;
        }
        /*判断token是否将要过期*/
        if (isTokenExpired() && config.url != "login/refreshToken") {
          /*判断是否正在刷新*/
          if (!window.isRefreshing) {
            /*将刷新token的标志置为true*/
            window.isRefreshing = true;
            /*发起刷新token的请求*/
            axios({
              method: "get",
              url: "login/refreshToken",
              params: {
                refreshToken: getRefreshToken(),
              },
            }).then((res) => {
              console.log(res);
              if (res.code == 200) {
                /*将标志置为false*/
                window.isRefreshing = false;
                /*成功刷新token*/
                config.headers.token = res.data;
                /*更新auth*/
                auth.token = res.data;
                // sessionStorage.setItem('userData', JSON.stringify(auth));
                localStorage.setItem("userData", JSON.stringify(auth));
                /*执行数组里的函数,重新发起被挂起的请求*/
                onRrefreshed(res.data);
                /*执行onRefreshed函数后清空数组中保存的请求*/
                refreshSubscribers = [];
              } else {
                /*清除本地保存的auth*/
                // sessionStorage.removeItem('userData');
                localStorage.removeItem("userData");
                let url = `${window.location.href.split("/admin/#")[0]}`;
                // window.location.href = '#/pc/login';
                window.location.href = url;
              }
            });
          }
          /*把请求(token)=>{....}都push到一个数组中*/
          let retry = new Promise((resolve, reject) => {
            /*(token) => {...}这个函数就是回调函数*/
            subscribeTokenRefresh((token) => {
              config.headers.token = token;
              /*将请求挂起*/
              resolve(config);
            });
          });
          return retry;
        }
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (res) => {
    loading.close();

    if (res.data.code !== 200) {
      if (res.data.code == 401) {
        localStorage.removeItem("userData");
        let href = window.location.href.split("/admin/#")[0];
        window.location.href = href;
      } else {
        vue.$message.error(res.data.message);
      }
      return;
    }

    return Promise.resolve(res.data);
  },
  (error) => {
    // console.log(error.request)
    // console.log(error.response)
    let message = error.response
      ? `${error.response.config.url}  ${error.response.status}`
      : error.message;
    loading.close();
    vue.$message.error(message);
    return Promise.reject(error);
  }
);

export default axios;
